import React from "react";

const Pages = ({ size = "1.5rem", fill = "#fff", stroke = "black" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke="#221b38"
        fill="#ffffff"
        d="M6 6H3C2.45 6 2 6.45 2 7V21C2 21.55 2.45 22 3 22H17C17.55 22 18 21.55 18 21V18H7C6.45 18 6 17.55 6 17V6Z"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke="#221b38"
        fill="#ffffff"
        d="M21 18H7C6.45 18 6 17.55 6 17V3C6 2.45 6.45 2 7 2H17.59C17.86 2 18.11 2.11 18.3 2.29L21.71 5.7C21.89 5.89 22 6.15 22 6.41V17C22 17.55 21.55 18 21 18Z"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke="#221b38"
        d="M18 14H10"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke="#221b38"
        d="M10 10H18"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke="#221b38"
        d="M10 6H13"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke="#221b38"
        fill="#ffffff"
        d="M17 2V7H22V6.41C22 6.15 21.89 5.89 21.71 5.71L18.29 2.29C18.11 2.11 17.85 2 17.59 2H17Z"
      ></path>
    </svg>
  );
};

export default Pages;
