import React, { useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BookCard } from "./BookCard";

import { LocalStateContext } from "../State/LocalState";

import styled from "styled-components";

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  font-size: 52px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 3fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";
`;

export const DragList = () => {
  const {
    loading,
    bookList,
    updateBookList,
    dragable,
    sorting,
    bookList: books,
    shadowList,
  } = useContext(LocalStateContext);

  let booksDisplayed = sorting === "ORDER_ASC" ? books : shadowList;

  const title = () => {
    switch (sorting) {
      case "ORDER_ASC":
        return "My noveList Reading Order";

      case "averageRating_DESC":
        return "Library: Best Rated";

      case "averageRating_ASC":
        return "Library: Worst Rated";

      case "title_ASC":
        return "Library: A - Z";

      case "title_DESC":
        return "Library: Z - A";

      case "pages_DESC":
        return "Library: Longest Books";

      case "pages_ASC":
        return "Library: Shortest Books";

      default:
        return "No Title";
    }
  };

  // const tokenization = async () => {
  //   const token = localStorage.getItem("user");
  //   console.log("token :>> ", token);
  //   await fetch("http://localhost:4000/auth", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((resp) => resp.json())
  //     .then((data) => {
  //       console.log("data_from_tokenization :>> ", data);
  //     });
  // };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#06455c75" : "#FFF",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#FFF" : "#FFF",
    padding: grid,
    width: 800,
    margin: "auto",
  });

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      bookList,
      result.source.index,
      result.destination.index
    );

    const mutatedList = newItems.map((element, index) => {
      return { ...element, order: index };
    });
    updateBookList(mutatedList);
    // // await saveBookList(newItems);

    // await mutateList(newItems);
  };

  return (
    <div style={{ margin: "auto", marginTop: "25px" }}>
      {loading && (
        <div className="flex justify-center mt-28">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      {!loading && !(booksDisplayed.length > 0) && (
        <Title>Add Books to noveList</Title>
      )}

      {booksDisplayed.length > 0 && (
        <>
          <Title>{title()}</Title>
          {/* <button onClick={() => tokenization()}>TEST TOKENIZATION</button> */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {booksDisplayed.map((item, index) => (
                    <Draggable
                      isDragDisabled={!dragable}
                      dog={"brown"}
                      key={index}
                      draggableId={String(index)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <CardGrid>
                            <h3>{dragable ? index + 1 + "." : ""}</h3>

                            <BookCard book={item} index={index} />
                          </CardGrid>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </div>
  );
};
