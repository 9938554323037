import React from "react";

const BookMark = ({ size = "1.5rem", fill = "#fff", stroke = "black" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke={stroke}
        fill={fill}
        d="M18 22L12 16L6 22V3C6 2.45 6.45 2 7 2H17C17.55 2 18 2.45 18 3V22Z"
      ></path>
    </svg>
  );
};

export default BookMark;
