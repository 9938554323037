import React, { useContext, useState } from "react";
import { LocalStateContext } from "../State/LocalState";
import styled from "styled-components";

import { updateOrigin } from "../Mutations/UpdateOrigin";

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5fr 2fr;
  gap: 1px 1px;
  grid-template-areas: "." ".";
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 3em;
  border: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-top: 6px solid #1a5970;
`;

const StatsItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "." "." "." "." ".";
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 6px;
  grid-row-gap: 0px;
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 3em;
  border: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-top: 6px solid #1a5970;
`;

const ContentGridItem = styled.div`
  img {
    height: 20rem;
  }

  .title {
    font-size: 42px;
    font-weight: 600;
    font-family: "Manrope", sans-serif;
  }

  .subtitle {
    font-size: 24px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
  }

  form {
    * {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const StatsItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: ". .";
  line-height: 1;
  margin-bottom: 5px;
`;

const ItemIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  ${({ color }) => {
    switch (color) {
      case "one":
        return "fill: #fff; background: #1a7065;";
      case "two":
        return "fill: #fff; background: #1a6a70;";
      case "three":
        return "fill: #fff; background: #1a5970;";
      case "four":
        return "fill: #fff; background: #1a4870;";
      case "five":
        return "fill: #fff; background: #1a3770;";
      case "warning":
        return "fill: #fff; background: #ffc107;";
      default:
        return "fill: #fff; background: #1a4870;";
    }
  }};
`;

const OriginUpdate = () => {
  const { totalPages, booksRead, bookList, bookGoal } =
    useContext(LocalStateContext);

  const [booksWithoutOrigin, setBooksWithoutOrigin] = useState(
    bookList.filter((book) => !book.book.originURL)
  );

  let FirstBook = booksWithoutOrigin[0] ? booksWithoutOrigin[0] : [];

  const [originURL, setOriginURL] = useState("");

  console.log("FirstBook", FirstBook);
  return (
    <div className="w-full">
      <StatsContainer>
        <StatsItem>Booklist Length: {bookList.length}</StatsItem>
        <StatsItem>
          Books Without Origin URL: {booksWithoutOrigin.length}
        </StatsItem>
      </StatsContainer>
      <ContentGrid>
        {booksWithoutOrigin.length === 0 && (
          <ContentGridItem>
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
              <div class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
                <div class="title">All Books Have Originating URLs</div>
              </div>
            </div>
          </ContentGridItem>
        )}
        {booksWithoutOrigin.length > 0 && (
          <ContentGridItem>
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
              <div class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
                <div class="title">Update Originating Reference</div>
              </div>
            </div>
          </ContentGridItem>
        )}
        {booksWithoutOrigin.length > 0 && (
          <ContentGridItem>
            <form>
              <div className="subtitle">Current Book</div>
              <div>Title: {FirstBook.book.title}</div>
              <div>Author: {FirstBook.book.author}</div>
              <input
                type="text"
                value={originURL}
                onChange={(e) => {
                  setOriginURL(e.target.value.trim());
                }}
              />
              <button
                type="button"
                onClick={() => {
                  updateOrigin({ bookData: FirstBook, originURL });
                  setBooksWithoutOrigin(
                    booksWithoutOrigin.filter(
                      (book) => book._id !== FirstBook._id
                    )
                  );
                  setOriginURL("");
                }}
              >
                Update Origin URL
              </button>
            </form>
          </ContentGridItem>
        )}
        {booksWithoutOrigin.length > 0 && (
          <ContentGridItem>
            <img src={FirstBook.book.image} alt="" />
          </ContentGridItem>
        )}
        {booksWithoutOrigin.length > 0 && (
          <ContentGridItem>
            <div>Origin URL: {originURL}</div>
          </ContentGridItem>
        )}
      </ContentGrid>
    </div>
  );
};

export default OriginUpdate;
