const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const updateOrigin = async ({
  bookData,
  user = "testtesttest",
  originURL,
}) => {
  console.log("CALLING Update Origin Mutation");
  console.log("bookData", bookData);
  console.log("bookData", user);
  const fetchData = await fetch(`${BACKEND_URL}/updateorigin`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: {
        bookData,
        user,
        originURL,
      },
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      console.log("DATA FROM MUTATION : ", data);
      return data;
    });
  return fetchData;
};
