import React from "react";
import { useState } from "react";

import { styled } from "styled-components";
import toast, { Toaster } from "react-hot-toast";

const ControlItem = styled.div`
  margin-bottom: 0.75rem; /* 12px */
`;

const ControlButton = styled.div`
    display: flex;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    border-radius: 0.25rem; /* 4px */
    color: #6c757d;
    border-color: #6c757d;
    height: 100%;
    display: inline-block;
    padding: .375rem .75rem;
    color: #6c757d;
    user-select: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #6c757d;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
   &:hover,
    &:focus {
      background-color: #a3bcc5;
      font-weight: 600;
      color: black;
    }
    
  
    &:focus,
    &.focus {
      outline: 0;
      box-shadow: $btn-focus-box-shadow;
  
    `;

const ModalAddBookForm = ({
  scrapeBookGoodReads,
  addBook,
  setBookList,
  setSorting,
  setDragable,
  bookList,
  user,
}) => {
  const [goodReadsURL, setGoodReadsURL] = useState("");

  const notify = (book) =>
    toast.success(`Successfully added ${book.title} by ${book.author}.`);

  const scrapeAndToast = async () => {
    toast.promise(scrapeAndAddBook(), {
      loading: "Loading",
      error: "Error when fetching",
    });
  };

  const scrapeAndAddBook = async () => {
    console.log("GOING TO SCRAPE AND ADD BOOK FROM CONTROL PANEL");
    if (goodReadsURL !== "") {
      const scrapedBook = await scrapeBookGoodReads(
        goodReadsURL,
        bookList.length
      );
      const bookData = scrapedBook.payload.book;
      console.log("bookData in scrapeAndAddBook", bookData);
      notify(bookData);
      console.log("bookList.length", bookList.length);

      const newBook = await addBook({
        bookData,
        user,
        length: bookList.length,
      });

      console.log("newBook", newBook);

      let bookToList = newBook.payload.book;

      setBookList([...bookList, { ...bookToList }]);
      setSorting("ORDER_ASC");
      setDragable(true);
      return bookData;
    }
  };
  return (
    <div>
      <div>
        <h4>
          <strong>Add Book from GoodReads</strong>
        </h4>
      </div>
      <div>Add a book to your list from a GoodReads URL provided below</div>
      <form>
        <label for="inputURL" className="form-label">
          <strong>URL:</strong>
        </label>
        <input
          type="text"
          id="inputURL"
          className="form-control"
          value={goodReadsURL}
          onChange={(e) => setGoodReadsURL(e.target.value)}
        />
        <div id="inputURLHelp" className="form-text">
          If successful, the book will be placed at the bottom of your current
          list.
        </div>
      </form>
      <div className="modal-footer">
        <ControlItem>
          <ControlButton
            onClick={() => {
              setGoodReadsURL("");
              scrapeAndToast();
            }}
          >
            <span> Add Book to noveList</span>
          </ControlButton>
        </ControlItem>
      </div>
    </div>
  );
};

export default ModalAddBookForm;
