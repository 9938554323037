//TODO: Fix the readinghistory year. At least on the live website, getting a blank page in the History Year, with the following error:
//TypeError: i.filter(...)[0] is undefined
//S_ YearStatsColumn.js:83

import React, { useContext } from "react";

import { LocalStateContext } from "../State/LocalState";
import styled from "styled-components";
import BookMark from "../Icons/Bookmark";
import Trophy from "../Icons/Trophy";
import Pages from "../Icons/Pages";
import Book from "../Icons/Book";
import Calendar from "../Icons/Calendar";

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5fr 2fr;
  gap: 1px 1px;
  grid-template-areas: "." ".";
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 3em;
  border: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-top: 6px solid #1a5970;
`;

const StatsItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "." "." "." "." ".";
`;

const StatsItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: ". .";
  line-height: 1;
  margin-bottom: 5px;
`;

const ItemIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  ${({ color }) => {
    switch (color) {
      case "one":
        return "fill: #fff; background: #1a7065;";
      case "two":
        return "fill: #fff; background: #1a6a70;";
      case "three":
        return "fill: #fff; background: #1a5970;";
      case "four":
        return "fill: #fff; background: #1a4870;";
      case "five":
        return "fill: #fff; background: #1a3770;";
      case "warning":
        return "fill: #fff; background: #ffc107;";
      default:
        return "fill: #fff; background: #1a4870;";
    }
  }};
`;

export const YearStatsColumn = ({ books, year }) => {
  const currentYear = year;

  const totalPages = books?.reduce((acc, value) => acc + value.pages, 0);

  const { bookGoals } = useContext(LocalStateContext);

  let bookGoal =
    bookGoals.length > 0
      ? bookGoals.filter((goal) => Number(goal.year) === Number(currentYear))[0]
          .goal
      : 0;

  console.log("bookGoal", bookGoal);

  return (
    <div>
      {books && (
        <StatsContainer>
          <StatsItemsContainer>
            <StatsItem>
              <ItemIcon color="one">
                <Trophy />
              </ItemIcon>

              <div>
                <h5>Reading Goal For {currentYear}:</h5>
                <p>
                  <strong>{bookGoal} books.</strong>
                </p>
              </div>
            </StatsItem>
            <StatsItem>
              <ItemIcon color="two">
                <Book />
              </ItemIcon>
              <div>
                <h5>Books Read This Year:</h5>
                <p>
                  <strong>{books.length} books.</strong>
                </p>
              </div>
            </StatsItem>
            <StatsItem>
              <ItemIcon color="three">
                <Pages />
              </ItemIcon>
              <div>
                <h5>Pages Read:</h5>
                <p>
                  <strong>{`${totalPages} pages.`}</strong>
                </p>
              </div>
            </StatsItem>
            <StatsItem>
              <ItemIcon color="four">
                <Calendar />
              </ItemIcon>
              <div>
                <h5>Average Pages Read:</h5>
                <p>
                  <strong>
                    {(totalPages / 365).toFixed(0)} pages per day.
                  </strong>
                </p>
              </div>
            </StatsItem>
            <StatsItem>
              <ItemIcon color="five">
                <BookMark />
              </ItemIcon>
              <div>
                <h5>Goal Difference:</h5>
                <p>
                  <strong>{bookGoal - books.length} books.</strong>
                </p>
              </div>
            </StatsItem>
          </StatsItemsContainer>
        </StatsContainer>
      )}
    </div>
  );
};
