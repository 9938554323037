//TODO: Have to refactor registration, still using old graphql mutations and routes, not working.

import React, { useState, useContext } from "react";
import styled from "styled-components";
import IllustrationBookshelf from "../Illustrations/Bookshelf";
import { LocalStateContext } from "../State/LocalState";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Title = styled.div`
  box-sizing: border-box;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
  margin-bottom: 1rem !important;
`;

const Label = styled.div`
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const FormControl = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const FormGroup = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  box-sizing: border-box;
  margin-bottom: 1rem;
`;

const FormButton = styled.div`
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  text-transform: none;
`;

const MainContainer = styled.div`
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 720px;
  width: 50% !important;
  margin-top: 3rem !important;
`;

const RegisterContainer = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
`;

const RegisterGraphic = styled.div`
  display: flex;

  flex-direction: column;

  flex-wrap: nowrap;

  justify-content: flex-start;

  align-content: center;

  align-items: center;
  margin-right: 1rem;
  text-align: left;
`;

export const Register = () => {
  let location = useLocation();
  const { setUser } = useContext(LocalStateContext);

  const registerUser = async ({ email, password, name }) => {
    console.log("ATTEMPTING TO REGISTER USER");

    await fetch(`${BACKEND_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload: {
          name,
          email,
          password,
        },
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log("data 2:>> ", data);
        let registeredUser = data.payload ? data.payload : false;
        if (registeredUser) {
          console.log("registeredUser  :>> ", registeredUser);

          localStorage.setItem("user", JSON.stringify(registeredUser));

          setUser(registeredUser);
          setRedirect(true);
        } else {
          alert("FAIL");
        }
      });
  };

  // data?.data?.createUser

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [redirect, setRedirect] = useState(false);

  return (
    <MainContainer>
      <Routes>
        <Route
          path="/auth"
          element={
            redirect && (
              <Navigate to="/main" state={{ from: location }} replace />
            )
          }
        />
      </Routes>

      <RegisterContainer>
        <RegisterGraphic>
          <Title>Register</Title>
          <IllustrationBookshelf width="334" height="250" />
        </RegisterGraphic>
        <div>
          <FormGroup>
            <Label for="exampleInputName">Name</Label>
            <FormControl
              type="text"
              id="exampleInputName"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleInputEmail1">Email address</Label>
            <FormControl
              type="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleInputPassword1">Password</Label>
            <FormControl
              type="password"
              id="exampleInputPassword1"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleInputPassword2">Confirm Password</Label>
            <FormControl
              type="password"
              id="exampleInputPassword2"
              placeholder="Password"
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
            />
          </FormGroup>

          <FormButton onClick={() => registerUser({ name, email, password })}>
            Register
          </FormButton>
        </div>
      </RegisterContainer>
    </MainContainer>
  );
};
